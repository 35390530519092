export default {
    primary: '#1B1A1E',
    primaryDark: '#121114',
    primaryLight: '#ACABAD',
    secondary: '#4A90E2',
    secondaryDark: '#3669A5',
    secondaryLight: '#8BB8EC',
    tertiary: '#FFFFFF',
    tertiaryDark: '#3c3c3b',
    tertiaryLight: '#888888',
};