import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { GlobalFonts } from "../../fonts"
import fonts from "../../fonts"
import colors from '../../styles/colors'
import {maxWidthContainer} from '../../styles/breakpoints'
import "animate.css/animate.min.css";

if (typeof window !== "undefined" && window.innerWidth) {
  if(window.innerWidth > maxWidthContainer.tablet) {
    require("smooth-scroll")('a[href*="#"]')
  }
}

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  min-height: 100vh;
`

const GlobalStyle = createGlobalStyle`
  html {
      height: 100%;
      width: 100%;
      overflow-x: hidden;
  }
  body {
      margin: 0;
      padding: 0;
      font-family: ${fonts.helvetica};
      color: ${colors.tertiaryDark};
      overflow-x: hidden;
      overflow-y: unset;
  }
  * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: 0s;
  }

  .animated {
    width: 100%;
  }
`

const Layout = ({ children }) => (
  <LayoutContainer>
    <GlobalStyle/>
    <GlobalFonts/>
    {children}
  </LayoutContainer>
)

export default Layout