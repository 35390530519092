import { createGlobalStyle } from "styled-components"

import helveticaneueWoff from "./helvetica-neue.woff"
import helveticaneueWoff2 from "./helvetica-neue.woff2"
import helveticaneueLightWoff from "./helvetica-neue-light.woff"
import helveticaneueLightWoff2 from "./helvetica-neue-light.woff2"
import helveticaneueBoldWoff from "./helvetica-neue-bold.woff"
import helveticaneueBoldWoff2 from "./helvetica-neue-bold.woff2"
import rocaWoff from "./roca.woff"
import rocaWoff2 from "./roca.woff2"
import rocaEot from "./roca.eot"
import rocaSvg from "./roca.svg"
import rocaTtf from "./roca.ttf"

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'roca';
    src: url('roca.eot');
    src:
      url('${rocaEot}?#iefix') format('embedded-opentype'),
      url('${rocaWoff2}') format('woff2'),
      url('${rocaWoff}') format('woff'),
      url('${rocaTtf}') format('truetype'),
      url('${rocaSvg}#rocabold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'helveticaneue';
    font-display: auto;
    src:
      url('${helveticaneueLightWoff2}') format('woff2'),
      url('${helveticaneueLightWoff}') format('woff');
    font-weight: 200;
    font-style: 200;
  }
  @font-face {
    font-family: 'helveticaneue';
    font-display: auto;
    src:
      url('${helveticaneueWoff2}') format('woff2'),
      url('${helveticaneueWoff}') format('woff');
    font-weight: 400;
    font-style: 400;
  }
  @font-face {
    font-family: 'helveticaneue';
    font-display: auto;
    src:
      url('${helveticaneueBoldWoff2}') format('woff2'),
      url('${helveticaneueBoldWoff}') format('woff');
    font-weight: 700;
    font-style: 700;
  }
`
export default {
  roca: "roca, Helvetica, Arial, sans-serif",
  helvetica: "helveticaneue, Helvetica, Arial, sans-serif"
}
